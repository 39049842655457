const globalNotice = {
  color: ({ theme }) => theme.colors.white,
  backgroundColor: {
    info: ({ theme }) => theme.colors.skogsgront,
    warning: ({ theme }) => theme.colors.cherryCobbler,
    critical: ({ theme }) => theme.colors.cherryCobbler,
  },
}

export default globalNotice
