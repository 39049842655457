import { css } from "styled-components"
import { rem } from "polished"

const hideScrollbar = () => css`
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* Chrome/Safari/Webkit */
    display: none;
  }
`

const overflowMask = (direction = `both`, size = 20) => {
  if (direction === `both`) {
    return css`
      mask: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) ${rem(size)},
        rgba(0, 0, 0, 1) calc(100% - ${rem(size)}),
        rgba(0, 0, 0, 0) 100%
      );
    `
  }

  if (direction === `right`) {
    return css`
      mask: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) calc(100% - ${rem(size)}),
        rgba(0, 0, 0, 0) 100%
      );
    `
  }

  if (direction === `left`) {
    return css`
      mask: linear-gradient(
        to left,
        rgba(0, 0, 0, 1) calc(100% - ${rem(size)}),
        rgba(0, 0, 0, 0) 100%
      );
    `
  }

  return ``
}

const waveUrl = (fill, square = false) => {
  const svg = square
    ? encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 19">
      <path fill="${fill}" d="M0 4.8V.577L.016.558 17 14.184 33.984.558 34 .577V4.8L17 18.423 0 4.8Z"/>
    </svg>
  `)
    : encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 12">
      <path fill="${fill}" d="m19.001 8.623.243.007.25.002c3.705 0 5.668-1.504 7.686-3.354l1.223-1.135.396-.36C30.94 1.863 33.54 0 38.001 0v2.75c-3.706 0-5.668 1.504-7.687 3.354L28.9 7.414l-.401.36c-2.103 1.856-4.68 3.608-9.006 3.608l-.248-.002-.245-.007-.367.008h-.125c-4.604 0-7.228-1.984-9.407-3.968l-1.414-1.31-.403-.364C5.399 4.059 3.459 2.749 0 2.749V0c4.74 0 7.381 2.103 9.598 4.143l1.223 1.135.404.365c1.884 1.679 3.824 2.989 7.283 2.989l.25-.002.243-.007Z"/>
    </svg>
  `)

  return `url("data:image/svg+xml,${svg}")`
}

export default {
  hideScrollbar,
  overflowMask,
  waveUrl,
}
