import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import EventCard from "../components/event-card"
import CardsGrid from "../components/cards-grid"
import { Heading1 } from "../components/styled/heading"
import { Paragraph } from "../components/styled/paragraph"

export default function EventsTemplate({
  data: { config, translations, events, image },
}) {
  const anyEvents = !!events.nodes.length

  return (
    <Layout>
      {image && (
        <Image>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={config.pages.events.title}
          />
        </Image>
      )}

      <Content>
        <Title>
          <Heading1
            as="span"
            dangerouslySetInnerHTML={{
              __html: config.pages.events.title,
            }}
          />
        </Title>

        {anyEvents && (
          <CardsGrid contained={false}>
            {events.nodes.map((event) => (
              <EventCard key={event.id} event={event} headingNum={3} />
            ))}
          </CardsGrid>
        )}

        {!anyEvents && (
          <Empty>
            <Paragraph
              dangerouslySetInnerHTML={{
                __html: translations.global.noContent,
              }}
            />
          </Empty>
        )}
      </Content>
    </Layout>
  )
}

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.pages.events.title }} />
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        noContent
      }
    }

    config: configJson {
      pages {
        events {
          title
        }
      }
    }

    events: allEventsJson(sort: { occurs_at: ASC }) {
      nodes {
        ...EventsCardFragment
      }
    }

    image: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/paddling.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

const Image = styled.div`
  height: 33vh;
  min-height: ${em(240)};
  max-height: ${em(480)};

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    min-height: ${em(180)};
  }
`

const Content = styled.div`
  ${({ theme }) => theme.grid.container()}

  padding-top: ${em(50)};
  padding-bottom: ${em(80)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(30)};
    padding-bottom: ${em(40)};
  }
`

const Title = styled.h1`
  margin-bottom: ${em(30)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${em(20)};
  }
`

const Empty = styled.div`
  max-width: 60ch;
`
