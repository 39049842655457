import React from "react"
import styled, { css } from "styled-components"
import { em } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import ImagePlaceholder from "../image-placeholder"
import { Heading4 } from "../styled/heading"
import { ParagraphSmall } from "../styled/paragraph"

export default function SiteCard({
  site: { name, path, image, excerpt },
  headingNum,
  hideOutline,
}) {
  return (
    <Container $hideOutline={hideOutline}>
      <Link to={path} aria-label={name}>
        <Media>
          {image?.mediaType == `image` && (
            <GatsbyImage image={image} alt={name} />
          )}

          {image?.mediaType == `video` && (
            <video src={image.url} autoPlay playsInline loop muted />
          )}

          {!image && <ImagePlaceholder alt={name} />}
        </Media>

        <Text>
          {name && (
            <Heading4
              as={`h${headingNum}`}
              dangerouslySetInnerHTML={{ __html: name }}
            />
          )}

          {excerpt && (
            <Description
              dangerouslySetInnerHTML={{
                __html: excerpt,
              }}
            />
          )}
        </Text>
      </Link>
    </Container>
  )
}

const Media = styled.figure`
  width: 100%;
  max-height: ${em(280)};
  aspect-ratio: 3 / 2;
  position: relative;
  overflow: hidden;
  border-top-left-radius: ${({ theme }) => theme.br.normal};
  border-top-right-radius: ${({ theme }) => theme.br.normal};
  background-color: ${({ theme }) => theme.colors.kullgront};

  @media ${({ theme }) => theme.mq.mediumDown} {
    max-height: ${em(180)};
  }

  a:hover & {
    opacity: 0.9;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    display: block;
  }

  video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    background-color: ${({ theme }) => theme.colors.black};
  }
`

const Description = styled(ParagraphSmall)`
  margin-top: ${em(6)};
`

const Text = styled.div`
  padding: ${em(16)} ${em(20)} ${em(20)};
  flex-grow: 1;
`

const Container = styled.article`
  width: 100%;
  display: flex;

  a {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.br.normal};

    ${({ $hideOutline }) =>
      $hideOutline &&
      css`
        outline: none;
      `}

    &:hover {
      background-color: ${({ theme }) => theme.colors.vattenbla200};
    }

    &:active {
      opacity: 0.8;
    }
  }
`
