module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://api.naturkartan.se","apiKey":"e7220628-7f56-44e2-86cb-150cea4bd350","guideId":"545","siteUrl":"https://www.ronnearingsjon.se","lang":"sv","typesCategories":{"hiking":["33","109","116","7"],"paddling":["37"],"biking":["31","16","119"],"fishing":["26","118","105","115"],"food":["54","106"],"discover":[]},"typesCategoriesIncluded":{"hiking":["33","109","116","7"],"paddling":["37"],"biking":["31","16","119"],"fishing":["26","118","105","115"],"food":["54","106"],"discover":[]},"includeListsInTours":true,"imagePlaceholderColor":"#588157","templates":{"type":"/opt/build/repo/src/templates/type.js","site":"/opt/build/repo/src/templates/site.js","page":"/opt/build/repo/src/templates/page.js","articles":"/opt/build/repo/src/templates/articles.js","articlesPost":"/opt/build/repo/src/templates/articles-post.js","news":"/opt/build/repo/src/templates/news.js","newsPost":"/opt/build/repo/src/templates/news-post.js","events":"/opt/build/repo/src/templates/events.js","eventsPost":"/opt/build/repo/src/templates/events-post.js","tours":"/opt/build/repo/src/templates/tours.js","toursPost":"/opt/build/repo/src/templates/tours-post.js","search":"/opt/build/repo/src/templates/search.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"sv","name":"Rönne å och Ringsjön","short_name":"Rönne å och Ringsjön","description":"Vattennära upplevelser som du bär med dig länge!","theme_color":"#224d91","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
