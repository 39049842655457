import { css } from "styled-components"
import { rem } from "polished"

const GUTTER_SIZE = 32

const CONTAINER_WIDTHS = {
  xnarrow: 640,
  narrow: 1080,
  normal: 1280,
  wide: 1440,
}

const container = (
  maxWidth = CONTAINER_WIDTHS.normal,
  { includeGutter = true } = {}
) => css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${maxWidth &&
  css`
    max-width: ${rem(
      typeof maxWidth === `string` ? CONTAINER_WIDTHS[maxWidth] : maxWidth
    )};
  `}

  ${includeGutter && gutter()}
`

const gutter = () => css`
  padding-left: ${rem(GUTTER_SIZE)};
  padding-right: ${rem(GUTTER_SIZE)};

  @media ${(props) => props.theme.mediumDown} {
    padding-left: ${rem(GUTTER_SIZE / 2)};
    padding-right: ${rem(GUTTER_SIZE / 2)};
  }
`

export default { container, gutter }
