import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
// import { StoreProvider as BrandSitesStoreProvider } from "@outdoormap/gatsby-plugin-brand-sites"
import { em } from "polished"

// import { StoreProvider } from "../../store"
import theme from "../../theme"

import "../../assets/stylesheets/index.scss"

export default function RootElement({ children }) {
  return (
    <ThemeProvider theme={theme}>
      {/* <BrandSitesStoreProvider language={process.env.GATSBY_LANG}> */}
      {/* <StoreProvider> */}
      <>
        <InitialStyles />

        {children}
      </>
      {/* </StoreProvider> */}
      {/* </BrandSitesStoreProvider> */}
    </ThemeProvider>
  )
}

const InitialStyles = createGlobalStyle`
  * {
    line-height: calc(2px + 2.3ex + 2px); /* https://hugogiraudel.com/2020/05/18/using-calc-to-figure-out-optimal-line-height/ */
    text-decoration-thickness: 1px;
    text-underline-offset: ${em(4)};
  }

  *:focus:not(:focus-visible) {
    outline: none;
  }

  html,
  body {
    overflow-x: clip;
  }

  html {
    ${({ theme }) => theme.fonts.set(`primary`, `normal`)}

    /* scroll-behavior: smooth; DOESNT SCROLL TO TOP ON PAGE SWITCH */
    scroll-padding-top: ${em(20)};
    font-size: 100%;  /* a11y */
    color: ${({ theme }) => theme.colors.foreground};
    background-color: ${({ theme }) => theme.colors.background};

    /* @media (prefers-reduced-motion: reduce) {
      scroll-behavior: auto;
    } */
  }

  body.--mobile-header,
  body.ReactModal__Body--open {
    ${({ theme }) => theme.helpers.hideScrollbar()}
  }

  strong {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration-line: underline;
  }
`
