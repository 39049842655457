import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { Link, markdown } from "@outdoormap/gatsby-plugin-brand-sites"

import Styled from "../styled"
import logoUrl from "../../assets/images/logo-white.svg"
// import { ReactComponent as SvgFacebook } from "../../assets/images/icons/facebook.svg"
import { ReactComponent as SvgInstagram } from "../../assets/images/icons/instagram.svg"
// import { ReactComponent as SvgTwitter } from "../../assets/images/icons/twitter.svg"
// import { ReactComponent as SvgYoutube } from "../../assets/images/icons/youtube.svg"

const SOCIAL = [
  // {
  //   id: `facebook`,
  //   title: `Facebook`,
  //   url: ``,
  //   Svg: SvgFacebook,
  // },
  {
    id: `instagram`,
    title: `Instagram`,
    url: `https://www.instagram.com/ronnearingsjon`,
    Svg: SvgInstagram,
  },
  // {
  //   id: `twitter`,
  //   title: `Twitter`,
  //   url: ``,
  //   Svg: SvgTwitter,
  // },
  // {
  //   id: `youtube`,
  //   title: `Youtube`,
  //   url: ``,
  //   Svg: SvgYoutube,
  // },
]

export default function About() {
  const { site, config } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          name
        }
      }

      config: configJson {
        footer {
          about
        }
      }
    }
  `)

  return (
    <Container>
      <Logo>
        <Link to="/" title={site.siteMetadata.name}>
          <img src={logoUrl} alt={site.siteMetadata.name} />
        </Link>
      </Logo>

      <Description
        dangerouslySetInnerHTML={{
          __html: markdown(config.footer.about),
        }}
      />

      {!!SOCIAL.length && (
        <Social>
          {SOCIAL.map(({ Svg, ...social }) => (
            <li key={social.id}>
              <Link
                to={social.url}
                target="_blank"
                rel="noreferrer noopener"
                title={social.title}
                aria-label={social.title}
              >
                <Svg aria-hidden="true" />
              </Link>
            </li>
          ))}
        </Social>
      )}
    </Container>
  )
}

const Container = styled.section``

const Logo = styled.h2`
  margin-bottom: ${em(20)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${em(10)};
  }

  a {
    display: inline-block;
    outline-offset: ${em(10)};

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5;
    }
  }

  img {
    width: ${em(140)};
    display: block;

    @media ${({ theme }) => theme.mq.mediumDown} {
      width: ${em(80)};
    }
  }
`

const Description = styled(Styled)`
  /* @media ${({ theme }) => theme.mq.mediumUp} {
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
  } */

  a:not(.do-unstyle) {
    color: inherit;
  }
`

const Social = styled.ul`
  margin-left: ${em(-8)};
  margin-top: ${em(20)};
  display: flex;
  gap: ${em(20)};
  row-gap: ${em(12)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(14)};
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    font-size: ${em(14)};
  }

  li {
    flex-shrink: 0;
  }

  a {
    width: ${em(40)};
    height: ${em(40)};
    padding: ${em(8)};
    display: block;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`
