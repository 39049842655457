const cookiesConsent = {
  textColor: ({ theme }) => theme.colors.grasten,
  borderColor: ({ theme }) => theme.colors.black,
  backgroundColor: ({ theme }) => theme.colors.granitsvart,
  br: ({ theme }) => theme.br.normal,
  buttonFont: ({ theme }) => theme.fonts.set(`primary`, `bold`),
  buttonTextColor: ({ theme }) => theme.colors.granitsvart,
  buttonBackgroundColor: ({ theme }) => theme.colors.sandbeige,
  buttonBr: ({ theme }) => theme.br.small,
}

export default cookiesConsent
