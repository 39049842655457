import React from "react"
import styled from "styled-components"
import { em } from "polished"

import Button from "../button"
import { Heading2 } from "../styled/heading"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

export default function CardsGrid({
  children,
  heading,
  headingNum,
  moreText,
  moreLink,
  contained = true,
  cols = 3,
}) {
  if (!Array.isArray(children) || !children.length) return null

  return (
    <Container as={heading ? `section` : `div`} $contained={contained}>
      {heading && (
        <Heading as={`h${headingNum}`}>
          <Heading2 as="span" dangerouslySetInnerHTML={{ __html: heading }} />
        </Heading>
      )}

      <Grid $cols={cols}>
        {children.map((card) => (
          <li key={card.key}>{card}</li>
        ))}
      </Grid>

      {moreText && moreLink && (
        <More>
          <Button
            to={moreLink}
            icon={<SvgArrowRight />}
            iconpos="right"
            display="anchor"
          >
            {moreText}
          </Button>
        </More>
      )}
    </Container>
  )
}

const Container = styled.section`
  ${({ theme, $contained }) => $contained && theme.grid.container()}
`

const Heading = styled.h2`
  margin-bottom: ${em(30)};
  color: ${({ theme }) => theme.colors.active};

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-bottom: ${em(20)};
  }
`

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(${({ $cols }) => $cols}, minmax(0, 1fr));
  gap: ${em(26)};

  @media ${({ theme }) => theme.mq.xlargeDown} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  > li {
    display: flex;
  }
`

const More = styled.div`
  margin-top: ${em(30)};
  text-transform: uppercase;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(20)};
  }
`
