import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { Meta, Map } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import { Heading5 } from "../components/styled/heading"
import { ReactComponent as SvgMapCompass } from "../assets/images/icons/map-compass.svg"

export default function MapTemplate({ data: { config } }) {
  return (
    <Layout>
      <Container>
        <Header>
          <h1>
            <SvgMapCompass aria-hidden="true" />

            <Heading5
              as="span"
              dangerouslySetInnerHTML={{
                __html: config.pages.map.title,
              }}
            />
          </h1>
        </Header>

        <MapWrap>
          <Map
            query={`guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`}
            menu="fullscreen"
            scrollZoom="false"
            styleControl="true"
            zoomControl="true"
            measureControl="true"
            locationControl="true"
            printControl="true"
            legendControl="true"
            legend="true"
            zoom={9}
          />
        </MapWrap>
      </Container>
    </Layout>
  )
}

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.pages.map.title }} />
}

export const pageQuery = graphql`
  query {
    config: configJson {
      pages {
        map {
          title
        }
      }
    }
  }
`

const Container = styled.article``

const Header = styled.header`
  padding: ${em(40)} 0;
  background-color: ${({ theme }) => theme.colors.grasten};

  @media ${({ theme }) => theme.mq.xxlargeDown} {
    padding-top: ${em(20)};
    padding-bottom: ${em(20)};
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(10)};
    padding-bottom: ${em(10)};
  }

  h1 {
    ${({ theme }) => theme.grid.container()}

    display: flex;
    align-items: center;
    gap: ${em(20)};
  }

  svg {
    width: ${em(44)};
    height: ${em(44)};
    flex-shrink: 0;

    @media ${({ theme }) => theme.mq.mediumDown} {
      width: ${em(32)};
      height: ${em(32)};
    }
  }
`

const MapWrap = styled.div`
  width: 100%;
  height: 80vh;
`
