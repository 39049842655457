import styled, { css } from "styled-components"
import { em } from "polished"

const paragraphStyles = css`
  font-size: ${em(18)};
  word-break: break-word;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(16)};
  }
`

const paragraphSmallStyles = css`
  font-size: ${em(16)};
  word-break: break-word;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(14)};
  }
`

const paragraphLargeStyles = css`
  font-size: ${em(20)};
  word-break: break-word;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(18)};
  }
`

const Paragraph = styled.p`
  ${paragraphStyles}
`

const ParagraphSmall = styled.p`
  ${paragraphSmallStyles}
`

const ParagraphLarge = styled.p`
  ${paragraphLargeStyles}
`

export {
  Paragraph,
  ParagraphSmall,
  ParagraphLarge,
  paragraphStyles,
  paragraphSmallStyles,
  paragraphLargeStyles,
}
