import React from "react"
import { graphql } from "gatsby"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Post from "../components/post"

export default function PageTemplate({ data: { page } }) {
  return (
    <Layout>
      <Post data={page} />
    </Layout>
  )
}

export const Head = ({ data: { page } }) => {
  return (
    <Meta
      data={{
        title: page.title,
        socialImage: page.images?.[0]?.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    page: pagesJson(id: { eq: $id }) {
      title
      body
      images {
        ...NKimageFragment
      }
      files {
        id
        url
        text
      }
      links {
        id
        url
        text
      }
      sites {
        ...SitesCardFragment
      }
    }
  }
`
