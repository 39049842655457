import styled, { css } from "styled-components"

import { em } from "polished"

const markStyles = css`
  padding: 0 ${em(6)};
  box-decoration-break: clone;
  background-color: ${({ theme }) => theme.colors.laxrose};
`

const Mark = styled.mark`
  ${markStyles}
`

export { Mark, markStyles }
