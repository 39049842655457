import React from "react"
import styled from "styled-components"
import { em } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"

import { Heading1, Heading5 } from "../styled/heading"
import TypeIconSvg from "../type-icon-svg"

export default function TypeHero({ type: { title, tagline, name }, img }) {
  return (
    <Container>
      {img && (
        <Image>
          <GatsbyImage
            image={img.childImageSharp.gatsbyImageData}
            alt={title}
          />
        </Image>
      )}

      <InfoWrap>
        <Info>
          <Heading>
            <TypeIconSvg name={name} aria-hidden="true" />

            <Heading1 as="span" dangerouslySetInnerHTML={{ __html: title }} />
          </Heading>

          {tagline && (
            <Tagline>
              <Heading5 as="p" dangerouslySetInnerHTML={{ __html: tagline }} />
            </Tagline>
          )}
        </Info>
      </InfoWrap>
    </Container>
  )
}

const Container = styled.section`
  height: 40vh;
  min-height: ${em(320)};
  max-height: ${em(640)};
  position: relative;
  display: flex;
  align-items: flex-end;

  @media ${({ theme }) => theme.mq.mediumDown} {
    min-height: ${em(180)};
  }
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.vattenbla400};

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const InfoWrap = styled.div`
  ${({ theme }) => theme.grid.container()}

  display: flex;
  position: relative;
  z-index: 2;
`

const Info = styled.div`
  max-width: ${em(480)};
  padding: ${em(18)} ${em(34)} ${em(22)};
  border-top-left-radius: ${({ theme }) => theme.br.large};
  border-top-right-radius: ${({ theme }) => theme.br.large};
  background-color: ${({ theme }) => theme.colors.abla};
  color: ${({ theme }) => theme.colors.sandbeige};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(12)};
  }
`

const Heading = styled.h1`
  display: flex;
  gap: ${em(14)};
  align-items: baseline;

  svg {
    width: ${em(42)};
    height: ${em(42)};
    position: relative;
    bottom: ${em(-4)};
    flex-shrink: 0;
  }
`

const Tagline = styled.div`
  margin-top: ${em(4)};
`
