import React from "react"
import styled, { css } from "styled-components"
import { em } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { anchorStyles } from "../styled/anchor"

const FONT_SIZE_BY_SIZE = {
  small: 14,
  normal: 16,
}

const FONT_WEIGHT_BY_DISPLAY = {
  button: `bold`,
  anchor: `semibold`,
}

export default function Button({
  children,
  to,
  icon,
  size,
  display,
  foreground,
  iconpos,
  ...rest
}) {
  rest = {
    size: `normal`,
    display: `button`,
    iconpos: `left`,
    ...rest,
  }

  return (
    <Container
      as={to ? Link : `button`}
      to={to}
      $size={size}
      $display={display}
      $foreground={foreground}
      $iconpos={iconpos}
      {...rest}
    >
      {icon && <Icon aria-hidden="true">{icon}</Icon>}

      <Text>{children}</Text>
    </Container>
  )
}

const Icon = styled.span``

const Text = styled.b``

const Container = styled.button`
  ${({ theme, $display }) =>
    theme.fonts.set(`primary`, FONT_WEIGHT_BY_DISPLAY[$display])};

  display: inline-flex;
  gap: ${em(10)};
  align-items: center;
  font-size: ${({ $size }) => em(FONT_SIZE_BY_SIZE[$size])};

  ${({ $display }) =>
    $display == `button` &&
    css`
      padding: ${em(12)} ${em(20)};
    `}

  ${({ $foreground }) =>
    $foreground &&
    css`
      color: ${({ theme, $foreground }) => theme.colors[$foreground]};
    `}

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  ${Icon} {
    width: ${em(20)};
    height: ${em(20)};
    flex-shrink: 0;
    order: ${({ $iconpos }) => ($iconpos == `right` ? 3 : 1)};

    svg,
    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    img {
      object-fit: contain;
    }
  }

  ${Text} {
    order: 2;

    ${({ $display }) =>
      $display == `anchor` &&
      css`
        ${anchorStyles}

        color: inherit;
      `}
  }
`
