import { rgba } from "polished"

let colors = {
  black: `#000000`,
  white: `#ffffff`,
  abla: `#1f4385`,
  vattenbla900: `#0077b6`,
  vattenbla800: `#0096c7`,
  vattenbla600: `#66abce`,
  vattenbla400: `#99c5df`,
  vattenbla200: `#d6e7f1`,
  grasten: `#efeeed`,
  forgedsteal: `#5a5a5a`,
  granitsvart: `#282828`,
  kullgront: `#588157`,
  skogsgront: `#345a40`,
  sandbeige: `#f4f1de`,
  desertSand: `#dad5b0`,
  laxrose: `#f7aca9`,
  cherryCobbler: `#884141`,
}

colors = {
  ...colors,
  // by function:
  background: colors.sandbeige,
  foreground: colors.granitsvart,
  active: colors.abla,
  alert: colors.cherryCobbler,
  lightBorder: rgba(colors.granitsvart, 0.2),
  imageBackground: colors.kullgront,
}

export default colors
