import React from "react"
import styled from "styled-components"
import { em } from "polished"

import logoUrl from "../../assets/images/logo-white.svg"

export default function ImagePlaceholder({ alt }) {
  return (
    <Container>
      <img src={logoUrl} alt={alt || ``} role="presentation" />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: ${em(20)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.kullgront};

  img {
    width: 100%;
    max-width: ${em(120)};
    opacity: 0.3;
  }
`
