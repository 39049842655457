import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { em, hideVisually } from "polished"
import { shuffle } from "lodash"
import {
  Meta,
  Map,
  ImagesCarousel,
  Infoblocks,
  Current,
  Expandable,
  Link,
} from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Infobox from "../components/infobox"
import SitesCarousel from "../components/sites-carousel"
import Styled from "../components/styled"
import { Heading2, Heading5, Heading7 } from "../components/styled/heading"

export default function SiteTemplate({ data: { translations, site } }) {
  const [nearbySites, setNearbySites] = useState(site.neighbours || [])

  useEffect(() => {
    setNearbySites(shuffle(site.neighbours || []))
  }, [])

  const mapQuery = `site_with_neighbours=${site.id}`
  // const mapQuery = [
  //   `site=${site.id}`,
  //   ...nearby.map((n) => `site=${n.id}`),
  // ].join(`&`)

  const NearbyComponent = ({ mode }) => {
    return (
      <Nearby $mode={mode}>
        <Heading7
          as="h2"
          dangerouslySetInnerHTML={{
            __html: translations.global.relatedPlaces,
          }}
        />

        <SitesCarousel
          sites={nearbySites}
          siteHeadingNum={3}
          visibleSlides={2}
          contained={false}
        />
      </Nearby>
    )
  }

  return (
    <Layout>
      <Container>
        <MapWrap>
          <h2 dangerouslySetInnerHTML={{ __html: translations.global.map }} />

          <Map
            query={mapQuery}
            preselectedSiteId={site.id}
            menu="fullscreen"
            scrollZoom="false"
            styleControl="fullscreen"
            zoomControl="true"
            measureControl="fullscreen"
            locationControl="fullscreen"
            printControl="fullscreen"
            legendControl="fullscreen"
          />
        </MapWrap>

        <MapType>
          <Link to={`/${site.theTypeData.slug}`}>
            <Heading5 as="span">{site.theTypeData.title}</Heading5>
          </Link>
        </MapType>

        <Columns>
          <Main>
            <Content>
              <Heading2
                as="h1"
                dangerouslySetInnerHTML={{ __html: site.name }}
              />

              {!!site.categories.length && (
                <Categories>
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: translations.global.categories,
                    }}
                  />

                  <ul>
                    {site.categories.map((category) => (
                      <li key={category.id}>
                        <img
                          src={`https://assets.naturkartan.se/assets/categories/${category.id}/main.svg`}
                          alt=""
                          role="presentation"
                        />

                        <span
                          dangerouslySetInnerHTML={{ __html: category.name }}
                        />
                      </li>
                    ))}
                  </ul>
                </Categories>
              )}

              <CurrentStyled data={site} Title={Heading5} Styled={Styled} />

              {site.description && (
                <Description>
                  <Expandable Toggle={ExpandableToggle}>
                    <Styled
                      $leadingParagraph={true}
                      dangerouslySetInnerHTML={{ __html: site.description }}
                    />
                  </Expandable>
                </Description>
              )}

              <InfoblocksStyled data={site} Title={Heading5} Styled={Styled} />
            </Content>

            {!!nearbySites.length && <NearbyComponent mode="desktop" />}
          </Main>

          <Aside>
            {!!site.images?.length && (
              <Images>
                <ImagesCarousel data={site.images} title={site.name} />
              </Images>
            )}

            <Infobox data={site} />
          </Aside>
        </Columns>

        {!!nearbySites.length && <NearbyComponent mode="mobile" />}
      </Container>
    </Layout>
  )
}

export const Head = ({ data: { site } }) => {
  return (
    <Meta
      data={{
        title: site.name,
        socialImage: site.images[0]?.images.fallback.src,
        metaDescription: site.metaDescription,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    translations: translationsJson {
      global {
        map
        categories
        relatedPlaces
      }
    }

    site: sitesJson(id: { eq: $id }) {
      id: nk_id
      path
      name
      description
      metaDescription: meta_description
      type
      theType: the_type
      theTypeData: the_type_data {
        name
        slug
        title
      }
      difficultyText: difficulty_text
      openingHours: opening_hours {
        open
        close
      }
      facts
      directions
      address
      telephone
      email
      length
      time
      surface
      hills
      vegetation
      current
      parking
      communications
      regulations
      categories {
        id: nk_id
        name
      }
      links {
        url
        text
        type
      }
      files {
        url
        text
      }
      images {
        ...NKimageFragment
      }
      neighbours {
        ...SitesCardFragment
      }
    }
  }
`

const Container = styled.article`
  padding-bottom: ${em(120)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-bottom: ${em(40)};
  }
`

const Columns = styled.div`
  ${({ theme }) => theme.grid.container(`narrow`)}

  margin-top: ${em(20)};
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(${em(240)}, 1fr);
  column-gap: ${em(80)};
  row-gap: ${em(30)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(10)};
    grid-template-columns: 1fr;
  }
`

const Main = styled.div``

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  gap: ${em(30)};
`

const Content = styled.div`
  width: 100%;
  max-width: ${em(560)};
`

const Categories = styled.section`
  width: 100%;
  margin-top: ${em(30)};

  h2 {
    ${hideVisually()}
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: ${em(20)};
    row-gap: ${em(14)};
  }

  li {
    display: flex;
    align-items: center;
    gap: ${em(10)};
  }

  img {
    width: ${em(24)};
    flex-shrink: 0;
  }

  span {
    ${({ theme }) => theme.fonts.set(`primary`, `medium`)};
  }
`

const Description = styled.div`
  ${Categories} + & {
    margin-top: ${em(40)};
    padding-top: ${em(40)};
    border-top: 1px solid ${({ theme }) => theme.colors.lightBorder};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${em(20)};
      padding-top: ${em(20)};
    }
  }
`

const MapWrap = styled.section`
  height: 60vh;
  min-height: ${em(320)};
  max-height: ${em(480)};
  position: relative;

  @media ${({ theme }) => theme.mq.mediumDown} {
    min-height: ${em(180)};
  }

  > h2 {
    ${hideVisually()}
  }
`

const MapType = styled.div`
  ${({ theme }) => theme.grid.container(`narrow`)};

  margin-top: ${em(20)};
  display: flex;

  a {
    outline-offset: ${em(6)};
    display: flex;
  }

  span {
    padding: ${em(6)} ${em(16)};
    padding-right: ${em(28)};
    background-color: ${({ theme }) => theme.colors.abla};
    clip-path: polygon(
      100% 0,
      calc(100% - ${em(12)}) 50%,
      100% 100%,
      0 100%,
      0 0
    );

    &:hover {
      background-color: ${({ theme }) => theme.colors.vattenbla900};
    }
  }

  ${Heading5} {
    color: ${({ theme }) => theme.colors.white};
  }
`

const Nearby = styled.section`
  margin-top: ${em(40)};
  padding-top: ${em(40)};
  border-top: 1px solid ${({ theme }) => theme.colors.lightBorder};

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.container(`narrow`)}

    margin-top: ${em(20)};
    padding-top: ${em(20)};

    ${({ $mode }) =>
      $mode == `desktop` &&
      css`
        display: none;
      `}
  }

  @media ${({ theme }) => theme.mq.mediumUp} {
    ${({ $mode }) =>
      $mode == `mobile` &&
      css`
        display: none;
      `}
  }

  ${Heading7} {
    margin-bottom: ${em(20)};
  }
`

const Images = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.br.normal};
`

const InfoblocksStyled = styled(Infoblocks)`
  margin-top: ${em(50)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(30)};
  }
`

const CurrentStyled = styled(Current)`
  margin: ${em(30)} 0;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin: ${em(20)} 0;
  }
`

const ExpandableToggle = styled.button`
  ${({ theme }) => theme.fonts.set(`primary`, `semibold`)};

  width: 100%;
  padding: ${em(10)} ${em(20)};
  text-align: center;
  border-radius: ${({ theme }) => theme.br.normal};
  background-color: ${({ theme }) => theme.colors.desertSand};

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
`
