// TODO: fix scroll-snap-align on free scroll

import React, { useRef, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { /* capitalize, */ sortBy, debounce } from "lodash"

// import { ReactComponent as SvgPin } from "../../assets/images/icons/pin.svg"
import { ReactComponent as SvgChevronLeft } from "../../assets/images/icons/chevron-left.svg"
import { ReactComponent as SvgChevronRight } from "../../assets/images/icons/chevron-right.svg"

export default function SitesFilter({
  categories,
  tags,
  selectedCategories,
  // selectedTags,
  onSelectCategories,
  onSelectTags,
}) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          all
          next
          previous
          categories
        }
      }
    }
  `)

  const [navPrevEnabled, setNavPrevEnabled] = useState(false)
  const [navNextEnabled, setNavNextEnabled] = useState(true)
  const categoriesScrollDirectionRef = useRef(`next`)
  const categoriesRef = useRef()

  if (categories.length <= 1 && !tags.length) return null

  categories = sortBy(categories, [`name`])
  tags = sortBy(tags, [`name`])

  const isAll = selectedCategories.length >= categories.length /* &&
    selectedTags.length >= tags.length*/

  const categoryClick = (e) => {
    const { id } = e.currentTarget.dataset
    let selected = []

    if (isAll) {
      selected.push(id)
    } else {
      selected = [...selectedCategories]
      if (selected.includes(id)) {
        selected = selected.filter((s) => s != id)
      } else {
        selected.push(id)
      }
    }

    onSelectCategories(selected)
    if (isAll) onSelectTags([])
  }

  // const tagClick = (e) => {
  //   const { id } = e.currentTarget.dataset
  //   let selected = []

  //   if (isAll) {
  //     selected.push(id)
  //   } else {
  //     selected = [...selectedTags]
  //     if (selected.includes(id)) {
  //       selected = selected.filter((s) => s != id)
  //     } else {
  //       selected.push(id)
  //     }
  //   }

  //   onSelectTags(selected)
  //   if (isAll) onSelectCategories([])
  // }

  const allClick = () => {
    onSelectCategories(categories.map((c) => c.id))
    onSelectTags(tags.map((t) => t.id))
  }

  const navClick = (e) => {
    const { direction } = e.currentTarget.dataset

    if (categoriesScrollDirectionRef.current != direction) {
      categoriesScrollDirectionRef.current = direction
      const { scrollLeft } = categoriesRef.current
      categoriesRef.current.dataset.direction = direction
      categoriesRef.current.scrollLeft = scrollLeft
    }

    categoriesRef.current.scrollBy({
      left: direction == `prev` ? -100 : 100,
      top: 0,
      behavior: `smooth`,
    })
  }

  const categoriesScroll = debounce(() => {
    const { scrollLeft, scrollWidth, offsetWidth } = categoriesRef.current

    setNavPrevEnabled(scrollLeft > 5)
    setNavNextEnabled(scrollLeft + 5 < scrollWidth - offsetWidth)
  }, 200)

  useEffect(() => {
    categoriesScroll()

    if (typeof window !== `undefined`)
      window.addEventListener(`resize`, categoriesScroll)

    return () => {
      if (typeof window !== `undefined`)
        window.removeEventListener(`resize`, categoriesScroll)
    }
  }, [])

  return (
    <Container role="listbox" aria-label={translations.global.categories}>
      {navPrevEnabled && (
        <Nav
          onClick={navClick}
          data-direction="prev"
          title={translations.global.previous}
          role="navigation"
        >
          <SvgChevronLeft aria-label={translations.global.previous} />
        </Nav>
      )}

      <Categories
        ref={categoriesRef}
        onScroll={categoriesScroll}
        data-direction="next"
      >
        <div>
          <Category
            onClick={allClick}
            dangerouslySetInnerHTML={{ __html: translations.global.all }}
            role="option"
            aria-selected={isAll}
          />

          {categories.map((item) => (
            <Category
              key={item.id}
              data-id={item.id}
              onClick={categoryClick}
              role="option"
              aria-selected={!isAll && selectedCategories.includes(item.id)}
            >
              <img
                src={`https://assets.naturkartan.se/assets/categories/${item.id}/outline.svg`}
                alt=""
                role="presentation"
              />

              {item.name}
            </Category>
          ))}

          {/* {tags.map((item) => (
            <Category
              key={item.id}
              data-id={item.id}
              onClick={tagClick}
              role="option"
              aria-selected={!isAll && selectedTags.includes(item.id)}
            >
              <SvgPin aria-hidden="true" />

              {capitalize(item.name)}
            </Category>
          ))} */}
        </div>
      </Categories>

      {navNextEnabled && (
        <Nav
          onClick={navClick}
          data-direction="next"
          title={translations.global.next}
          role="navigation"
        >
          <SvgChevronRight aria-label={translations.global.next} />
        </Nav>
      )}
    </Container>
  )
}

const Container = styled.div`
  ${({ theme }) => theme.grid.container()}

  position: relative;
`

const Nav = styled.button.attrs({ type: `button` })`
  width: ${em(40)};
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  color: ${({ theme }) => theme.colors.abla};

  &:hover {
    color: ${({ theme }) => theme.colors.vattenbla800};
  }

  &:active {
    opacity: 0.7;
  }

  &[data-direction="prev"] {
    left: ${em(-20)};
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0),
      ${({ theme }) => theme.colors.background} 33%,
      ${({ theme }) => theme.colors.background}
    );

    @media ${({ theme }) => theme.mq.xxxlargeDown} {
      left: 0;
    }
  }

  &[data-direction="next"] {
    right: ${em(-20)};
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      ${({ theme }) => theme.colors.background} 33%,
      ${({ theme }) => theme.colors.background}
    );

    @media ${({ theme }) => theme.mq.xxxlargeDown} {
      right: 0;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    padding: ${em(14)};
  }
`

const Category = styled.button.attrs({ type: `button` })`
  padding: ${em(6)} ${em(16)};
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.br.small};
  outline-offset: ${em(4)};

  &:hover {
    border-color: ${({ theme }) => theme.colors.vattenbla900};
  }

  &[aria-selected="true"] {
    border-color: transparent;
    background-color: ${({ theme }) => theme.colors.vattenbla900};
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      background-color: ${({ theme }) => theme.colors.vattenbla800};
    }

    img {
      filter: grayscale(1) brightness(0) invert(1);
    }
  }

  img,
  svg {
    width: ${em(20)};
    height: ${em(20)};
    margin-right: ${em(10)};
  }

  img {
    filter: grayscale(1) brightness(0);
  }

  svg {
    height: ${em(14)};
  }
`

const Categories = styled.div`
  ${({ theme }) => theme.helpers.hideScrollbar()}
  /* ${({ theme }) => theme.helpers.overflowMask(`right`)} */

  padding-top: ${em(4)};
  padding-bottom: ${em(4)};
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  position: relative;
  z-index: 1;

  div {
    display: inline-flex;
    gap: ${em(6)};
  }

  &[data-direction="prev"] {
    ${Category} {
      scroll-snap-align: start;
    }
  }

  &[data-direction="next"] {
    ${Category} {
      scroll-snap-align: end;
    }
  }
`
