import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { em } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import TypeIconSvg from "../type-icon-svg"

export default function Hero({ title, video, image }) {
  const {
    config: { types },
  } = useStaticQuery(graphql`
    query {
      config: configJson {
        types {
          name
          slug
          title
        }
      }
    }
  `)

  if (!title) return null

  return (
    <Container>
      <Screen>
        <Media>
          {video && (
            <video src={video} preload="auto" playsInline autoPlay loop muted />
          )}

          {image && (
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              alt={title}
            />
          )}
        </Media>

        {!!types.length && (
          <Types $count={types.length}>
            {types.map(({ name, slug, title }) => (
              <li key={name}>
                <TypeLink to={`/${slug}`}>
                  <TypeIconSvg name={name} />

                  <span>{title}</span>
                </TypeLink>
              </li>
            ))}
          </Types>
        )}
      </Screen>

      <Title>
        <div>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </Title>
    </Container>
  )
}

const Container = styled.section`
  background-color: ${({ theme }) => theme.colors.vattenbla400};
`

const Screen = styled.div`
  height: 66vh;
  max-height: ${em(1280)};
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

const Media = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.4)
    );
  }

  video,
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    display: block;
  }

  video {
    object-fit: cover;
  }
`

const Title = styled.h1`
  padding: ${em(32)} 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.abla};
  color: ${({ theme }) => theme.colors.sandbeige};

  > div {
    ${({ theme }) => theme.grid.container()}

    width: auto;
    padding-top: ${em(36)};
    padding-bottom: ${em(36)};
    display: inline-block;
    background-image: ${({ theme }) =>
        theme.helpers.waveUrl(theme.colors.sandbeige, true)},
      ${({ theme }) => theme.helpers.waveUrl(theme.colors.sandbeige)};
    background-position:
      center top,
      center bottom;
    background-repeat: repeat-x;
    background-size: ${em(30)}, ${em(36)};

    @media ${({ theme }) => theme.mq.smallDown} {
      background-size: ${em(24)} ${em(30)};
    }
  }

  span {
    ${({ theme }) => theme.fonts.set(`primary`, `semibold`)};

    font-size: clamp(${em(24)}, 4vw, ${em(44)});
    line-height: 1.1;
    display: inline-block;
  }
`

const Types = styled.ul`
  ${({ theme }) => theme.grid.container()}

  margin: ${em(120)} 0 ${em(40)};
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(${({ $count }) => $count}, minmax(0, 1fr));
  gap: ${em(18)};

  @media ${({ theme }) => theme.mq.largeDown} {
    grid-template-columns: repeat(
      ${({ $count }) => Math.floor($count / 2)},
      minmax(0, 1fr)
    );
    font-size: ${em(14)};
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    grid-template-columns: repeat(
      ${({ $count }) => Math.floor($count / 3)},
      minmax(0, 1fr)
    );
    font-size: ${em(12)};
  }
`

const TypeLink = styled(Link)`
  padding: ${em(14)} ${em(22)};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${em(10)};
  background-color: ${({ theme }) => theme.colors.abla};
  color: ${({ theme }) => theme.colors.sandbeige};
  border-radius: ${({ theme }) => theme.br.normal};
  outline-offset: ${em(4)};

  &:hover {
    color: ${({ theme }) => theme.colors.granitsvart};
    background-color: ${({ theme }) => theme.colors.vattenbla400};
  }

  span {
    ${({ theme }) => theme.fonts.set(`primary`, `semibold`)};

    font-size: ${em(22)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    width: ${em(26)};
    height: ${em(26)};
    flex-shrink: 0;
  }
`
