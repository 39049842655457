import { useState, useRef } from "react"

export default function useRefState(initialValue) {
  const [state, setState] = useState(initialValue)
  const reference = useRef(state)

  const setRefState = (value) => {
    reference.current = value
    setState(value)
  }

  return [state, reference, setRefState]
}
