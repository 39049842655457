import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import PostCard from "../components/post-card"
import CardsGrid from "../components/cards-grid"
import { Heading1 } from "../components/styled/heading"
import { Paragraph } from "../components/styled/paragraph"

export default function NewsTemplate({
  data: { config, translations, news, image },
}) {
  const anyNews = !!news.nodes.length

  return (
    <Layout>
      <article>
        {image && (
          <Image>
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              alt={config.pages.news.title}
            />
          </Image>
        )}

        <Content>
          <Title>
            <Heading1
              as="span"
              dangerouslySetInnerHTML={{
                __html: config.pages.news.title,
              }}
            />
          </Title>

          {anyNews && (
            <CardsGrid contained={false}>
              {news.nodes.map((post) => (
                <PostCard key={post.id} post={post} headingNum={2} />
              ))}
            </CardsGrid>
          )}

          {!anyNews && (
            <Empty>
              <Paragraph
                dangerouslySetInnerHTML={{
                  __html: translations.global.noContent,
                }}
              />
            </Empty>
          )}
        </Content>
      </article>
    </Layout>
  )
}

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.pages.news.title }} />
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        noContent
      }
    }

    config: configJson {
      pages {
        news {
          title
        }
      }
    }

    news: allNewsJson(sort: { publish_at: DESC }) {
      nodes {
        ...NewsCardFragment
      }
    }

    image: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/discover.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

const Image = styled.div`
  height: 33vh;
  min-height: ${em(240)};
  max-height: ${em(480)};

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    min-height: ${em(180)};
  }
`

const Content = styled.div`
  ${({ theme }) => theme.grid.container()}

  padding-top: ${em(50)};
  padding-bottom: ${em(80)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(30)};
    padding-bottom: ${em(40)};
  }
`

const Title = styled.h1`
  margin-bottom: ${em(30)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${em(20)};
  }
`

const Empty = styled.div`
  max-width: 60ch;
`
