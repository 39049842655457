import styled, { css } from "styled-components"
import { em } from "polished"

const heading1Styles = css`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  font-size: ${em(50)};
  word-break: break-word;
  line-height: 1.1;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(36)};
  }
`

const heading2Styles = css`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  font-size: ${em(36)};
  word-break: break-word;
  line-height: 1.1;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(26)};
  }
`

const heading3Styles = css`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  font-size: ${em(26)};
  word-break: break-word;
  line-height: 1.1;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(20)};
  }
`

const heading4Styles = css`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  font-size: ${em(20)};
  word-break: break-word;
  line-height: 1.2;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(18)};
  }
`

const heading5Styles = css`
  ${({ theme }) => theme.fonts.set(`secondary`, `semibold`)}

  text-transform: uppercase;
  font-size: ${em(18)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(16)};
  }
`

const heading6Styles = css`
  ${({ theme }) => theme.fonts.set(`secondary`, `semibold`)}

  text-transform: uppercase;
  font-size: ${em(16)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(14)};
  }
`

const heading7Styles = css`
  ${({ theme }) => theme.fonts.set(`secondary`, `semibold`)}

  text-transform: uppercase;
  font-size: ${em(14)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(13)};
  }
`

const Heading1 = styled.h1`
  ${heading1Styles}
`

const Heading2 = styled.h2`
  ${heading2Styles}
`

const Heading3 = styled.h3`
  ${heading3Styles}
`

const Heading4 = styled.h4`
  ${heading4Styles}
`

const Heading5 = styled.h5`
  ${heading5Styles}
`

const Heading6 = styled.h6`
  ${heading6Styles}
`

const Heading7 = styled.h6`
  ${heading7Styles}
`

export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Heading7,
  heading1Styles,
  heading2Styles,
  heading3Styles,
  heading4Styles,
  heading5Styles,
  heading6Styles,
  heading7Styles,
}
