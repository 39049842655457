import React, { useState, useEffect } from "react"
import { RemoveScroll } from "react-remove-scroll"
import FocusTrap from "focus-trap-react"
import { useMediaQuery } from "@react-hook/media-query"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { em } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import Nav from "./nav"
import { ReactComponent as DeleteSvg } from "../../assets/images/icons/delete.svg"
import { ReactComponent as MenuSvg } from "../../assets/images/icons/menu.svg"
import logoUrl from "../../assets/images/logo.svg"
import mq from "../../theme/mq"

export default function Header({ tagName = `header` }) {
  const { site, translations } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          name
        }
      }

      translations: translationsJson {
        global {
          menuHide
          menuShow
        }
      }
    }
  `)

  const [mobileActive, setMobileActive] = useState(false)
  const [navSearchActive, setNavSearchActive] = useState(false)
  const mqMobile = useMediaQuery(mq.mediumDown)

  useEffect(() => {
    if (!mqMobile) setMobileActive(false)
  }, [mqMobile])

  useEffect(() => {
    document.body.classList.toggle(`--mobile-header`, mobileActive)
  }, [mobileActive])

  const toggleClick = () => setMobileActive(!mobileActive)
  const navSearchOpen = () => setNavSearchActive(true)
  const navSearchClose = () => setNavSearchActive(false)

  return (
    <RemoveScroll enabled={mobileActive}>
      <FocusTrap active={mobileActive && !navSearchActive}>
        <Container as={tagName} $active={mobileActive}>
          <Inner>
            <Bar $active={mobileActive}>
              <Logo>
                <Link to="/" title={site.siteMetadata.name}>
                  <img src={logoUrl} alt={site.siteMetadata.name} />
                </Link>
              </Logo>

              <Toggle
                title={
                  mobileActive
                    ? translations.global.menuHide
                    : translations.global.menuShow
                }
                onClick={toggleClick}
                aria-expanded={mobileActive}
                aria-controls="header-nav"
              >
                {mobileActive ? (
                  <DeleteSvg aria-label={translations.global.menuHide} />
                ) : (
                  <MenuSvg aria-label={translations.global.menuShow} />
                )}
              </Toggle>
            </Bar>

            <NavWrap id="header-nav" aria-label="Main navigation">
              <Nav
                onSearchOpen={navSearchOpen}
                onSearchClose={navSearchClose}
              />
            </NavWrap>
          </Inner>
        </Container>
      </FocusTrap>
    </RemoveScroll>
  )
}

const Bar = styled.div`
  flex-shrink: 0;
  order: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.container()}

    padding-top: ${em(12)};
    padding-bottom: ${em(12)};

    ${({ $active }) =>
      $active &&
      css`
        position: sticky;
        z-index: 10;
        top: 0;
        background-color: ${({ theme }) => theme.colors.background};
        border-bottom: 1px solid ${({ theme }) => theme.colors.foreground};
      `}
  }
`

const Logo = styled.div`
  a {
    display: block;
    outline-offset: ${em(10)};

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5;
    }
  }

  img {
    width: ${em(136)};
    height: ${em(60)};
    display: block;

    @media ${({ theme }) => theme.mq.mediumDown} {
      width: ${em(100)};
      height: ${em(46)};
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      width: ${em(80)};
      height: ${em(40)};
    }
  }
`

const Toggle = styled.button.attrs({ type: `button` })`
  width: ${em(40)};
  height: ${em(40)};
  padding: ${em(10)};
  margin-right: ${em(-10)};
  flex-shrink: 0;

  @media ${({ theme }) => theme.mq.mediumUp} {
    display: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.active};
  }

  &:active {
    opacity: 0.5;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const NavWrap = styled.nav`
  flex: 1;
  order: 3;
  display: flex;
  justify-content: flex-end;

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.container()}

    display: none;
    padding-top: ${em(12)};
    padding-bottom: ${em(100)};
  }
`

const Inner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.mq.mediumUp} {
    ${({ theme }) => theme.grid.container()}
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    display: block;
  }
`

const Container = styled.header`
  width: 100%;
  padding-top: ${em(20)};
  padding-bottom: ${em(20)};
  position: relative;
  z-index: ${({ theme }) => theme.zindex.header};
  display: flex;
  align-items: center;

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: 0;
    padding-bottom: 0;
  }

  ${({ $active }) =>
    $active &&
    css`
      @media ${({ theme }) => theme.mq.mediumDown} {
        ${({ theme }) => theme.helpers.hideScrollbar()}

        width: 100%;
        height: 100%;
        display: block;
        position: fixed;
        z-index: ${({ theme }) => theme.zindex.headerActive};
        top: 0;
        left: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
        background-color: ${({ theme }) => theme.colors.background};

        ${NavWrap} {
          display: block;
        }
      }
    `}
`
