import styled, { css } from "styled-components"

const anchorStyles = css`
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.active};

  &:hover:is(a, button) {
    opacity: 0.8;
  }

  &:active:is(a, button) {
    opacity: 0.6;
  }
`

const Anchor = styled.a`
  ${anchorStyles}
`

export { Anchor, anchorStyles }
