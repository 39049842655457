const typesCategories = {
  hiking: [
    `33`, // hiking
    `109`, // circular-trail
    `116`, // pilgrim-trail
    `7`, // walking
  ],
  paddling: [
    `37`, // canoeing
  ],
  biking: [
    `31`, // biking
    `16`, // mountainbiking
    `119`, // bikeservice
  ],
  fishing: [
    `26`, // fishing
    `118`, // boat-launch
    `105`, // fishing-permit
    `115`, // jetty
  ],
  food: [
    `54`, // cafe
    `106`, // ice-cream
  ],
  discover: [],
}

const typesCategoriesIncluded = typesCategories
const typesCategoriesPreselected = typesCategories

module.exports = {
  typesCategories,
  typesCategoriesIncluded,
  typesCategoriesPreselected,
}
