import { ReactComponent as SvgHiking } from "../../assets/images/icons/hiking.svg"
import { ReactComponent as SvgPaddling } from "../../assets/images/icons/paddling.svg"
import { ReactComponent as SvgBiking } from "../../assets/images/icons/biking.svg"
import { ReactComponent as SvgFishing } from "../../assets/images/icons/fishing.svg"
import { ReactComponent as SvgFood } from "../../assets/images/icons/food.svg"
import { ReactComponent as SvgDiscover } from "../../assets/images/icons/discover.svg"

const icons = {
  hiking: SvgHiking,
  paddling: SvgPaddling,
  biking: SvgBiking,
  fishing: SvgFishing,
  food: SvgFood,
  discover: SvgDiscover,
}

export default function TypeIconSvg({ name, ...props }) {
  if (!name) return null

  return icons[name](props)
}
