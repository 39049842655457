import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"

import Button from "../button"
import CardsCarousel from "../cards-carousel"
import SiteCard from "../site-card"
import TypeIconSvg from "../type-icon-svg"
import { Heading2 } from "../styled/heading"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

export default function SitesCarousel({
  sites,
  type,
  headingNum,
  siteHeadingNum,
  visibleSlides = 3,
  contained = true,
}) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          seeAll
        }
      }
    }
  `)

  if (!sites.length) return null

  return (
    <Container as={type ? `section` : `div`} $contained={contained}>
      {type && (
        <Header>
          <Icon>
            <TypeIconSvg name={type.name} />
          </Icon>

          {type.title && (
            <Heading as={`h${headingNum}`}>
              <Heading2
                as="span"
                dangerouslySetInnerHTML={{ __html: type.title }}
              />
            </Heading>
          )}

          {type.tagline && (
            <Description dangerouslySetInnerHTML={{ __html: type.tagline }} />
          )}
        </Header>
      )}

      <CardsCarousel visibleSlides={visibleSlides}>
        {sites.map((s) => (
          <SiteCard
            key={s.id}
            site={s}
            headingNum={siteHeadingNum || headingNum + 1}
            hideOutline={true}
          />
        ))}
      </CardsCarousel>

      {type && (
        <More>
          <Button
            to={`/${type.slug}`}
            icon={<SvgArrowRight />}
            iconpos="right"
            display="anchor"
          >
            {translations.global.seeAll}
          </Button>
        </More>
      )}
    </Container>
  )
}

const Header = styled.header`
  margin-bottom: ${em(20)};
  display: grid;
  grid-template-areas:
    "a b"
    "a c";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  column-gap: ${em(14)};
`

const Icon = styled.div`
  width: ${em(60)};
  height: ${em(60)};
  grid-area: a;
  color: ${({ theme }) => theme.colors.sandbeige};
  background-color: ${({ theme }) => theme.colors.abla};
  border-radius: ${({ theme }) => theme.br.small};

  svg {
    width: 100%;
    height: 100%;
    display: block;
    padding: ${em(14)};
  }
`

const Heading = styled.h2`
  grid-area: b;
  color: ${({ theme }) => theme.colors.abla};
`

const Description = styled.p`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)};

  grid-area: c;
  text-transform: uppercase;
`

const More = styled.div`
  margin-top: ${em(30)};
  text-transform: uppercase;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(20)};
  }
`

const Container = styled.section`
  ${({ theme, $contained }) => $contained && theme.grid.container()}
`
