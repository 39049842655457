exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-articles-post-js": () => import("./../../../src/templates/articles-post.js" /* webpackChunkName: "component---src-templates-articles-post-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-map-js": () => import("./../../../src/templates/map.js" /* webpackChunkName: "component---src-templates-map-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-site-js": () => import("./../../../src/templates/site.js" /* webpackChunkName: "component---src-templates-site-js" */),
  "component---src-templates-tours-js": () => import("./../../../src/templates/tours.js" /* webpackChunkName: "component---src-templates-tours-js" */),
  "component---src-templates-tours-post-js": () => import("./../../../src/templates/tours-post.js" /* webpackChunkName: "component---src-templates-tours-post-js" */),
  "component---src-templates-type-js": () => import("./../../../src/templates/type.js" /* webpackChunkName: "component---src-templates-type-js" */)
}

