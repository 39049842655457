import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { em } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import Button from "../button"
import ImagePlaceholder from "../image-placeholder"
import { Heading3, Heading4 } from "../styled/heading"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

export default function PostCardHighlight({
  post: { title, path, image, date },
  headingNum,
  major,
}) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          readMore
        }
      }
    }
  `)

  const Heading = major ? Heading3 : Heading4

  return (
    <Container>
      <Link to={path} aria-label={title}>
        <Media>
          {image?.mediaType == `image` && (
            <GatsbyImage image={image} alt={title} />
          )}

          {image?.mediaType == `video` && (
            <video src={image.url} autoPlay playsInline loop muted />
          )}

          {!image && <ImagePlaceholder alt={title} />}
        </Media>

        <Info $major={major}>
          {date && (
            <Date dateTime={date} dangerouslySetInnerHTML={{ __html: date }} />
          )}

          {title && (
            <Title as={`h${Math.min(headingNum, 6)}`}>
              <Heading as="span" dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          )}

          <More aria-hidden="true">
            <Button
              as="p"
              display="anchor"
              size="small"
              iconpos="right"
              icon={<SvgArrowRight />}
            >
              {translations.global.readMore}
            </Button>
          </More>
        </Info>
      </Link>
    </Container>
  )
}

const Media = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.kullgront};
  border-radius: ${({ theme }) => theme.br.normal};

  .gatsby-image-wrapper,
  video {
    width: 100%;
    height: 100%;
    display: block;

    a:hover & {
      opacity: 0.9;
    }
  }

  video {
    object-fit: cover;
  }
`

const Info = styled.div`
  width: 100%;
  padding: ${em(16)} ${em(30)} ${em(22)};
  position: relative;
  z-index: 2;
  color: ${({ theme }) => theme.colors.sandbeige};
  background-color: ${({ theme }) => theme.colors.abla};
  border-bottom-left-radius: ${({ theme }) => theme.br.normal};
  border-bottom-right-radius: ${({ theme }) => theme.br.normal};

  ${({ $major }) =>
    $major &&
    css`
      padding: ${em(28)} ${em(40)} ${em(34)};
    `}

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${em(10)} ${em(20)} ${em(16)};
  }

  a:hover & {
    color: ${({ theme }) => theme.colors.granitsvart};
    background-color: ${({ theme }) => theme.colors.vattenbla400};
  }
`

const Date = styled.time`
  margin-bottom: ${em(4)};
  display: block;
  font-size: ${em(14)};
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.grasten};

  a:hover & {
    color: inherit;
  }
`

const More = styled.div`
  margin-top: ${em(4)};
`

const Container = styled.article`
  width: 100%;
  display: flex;

  a {
    width: 100%;
    min-height: 100%;
    padding-top: 50%;
    position: relative;
    display: flex;
    align-items: flex-end;
    outline-offset: ${em(6)};

    &:active {
      opacity: 0.8;
    }
  }
`
